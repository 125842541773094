import { Component } from "react";

class History extends Component {
  render() {
    return (
      <div>
        <h1>History</h1>
      </div>
    );
  }
}

export default History;
