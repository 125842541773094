import React from "react";

import { Routes, Route, BrowserRouter } from "react-router-dom";

import Main from "./pages/Main";
import ErrorPage from "./pages/404";
import PrivatePolicy from "./pages/PrivatePolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import Auth from "./controllers/Auth";
import Authenticated from "./pages/Authenticated";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            Auth.getToken() ? <Authenticated page={"activity"} /> : <Main />
          }
        />
        <Route
          path="/documents"
          element={
            Auth.getToken() ? (
              <Authenticated page={"documents"} />
            ) : (
              <ErrorPage />
            )
          }
        />
        <Route
          path="/sources"
          element={
            Auth.getToken() ? <Authenticated page={"sources"} /> : <ErrorPage />
          }
        />
        <Route
          path="/queries"
          element={
            Auth.getToken() ? <Authenticated page={"queries"} /> : <ErrorPage />
          }
        />
        <Route
          path="/presentations"
          element={
            Auth.getToken() ? (
              <Authenticated page={"presentations"} />
            ) : (
              <ErrorPage />
            )
          }
        />
        <Route
          path="/history"
          element={
            Auth.getToken() ? <Authenticated page={"history"} /> : <ErrorPage />
          }
        />
        <Route path="/privacy_policy" element={<PrivatePolicy />} />
        <Route path="/terms_and_conditions" element={<TermsAndConditions />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
