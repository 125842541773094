import { Component } from "react";

import { FooterProps } from "./Footer.types";

import "animate.css";

interface FooterState {}

const navigation = {
  main: [
    { name: "Privacy Policy", href: "/privacy_policy" },
    { name: "Terms of Conditions", href: "/terms_and_conditions" },
  ],
};

export default class Footer extends Component<FooterProps, FooterState> {
  render() {
    return (
      <footer
        className={`
          bg-white
          md:shadow-lg
        `}
      >
        <div
          className={`
            mx-auto
            max-w-7xl
            overflow-hidden
            px-6 py-20
            sm:py-24
            lg:px-8
          `}
        >
          <nav
            className={`
              -mb-6
              columns-2
              sm:flex
              sm:justify-center
              sm:space-x-12
            `}
            aria-label={`Footer`}
          >
            {navigation.main.map((item) => (
              <div key={item.name} className="pb-6">
                <a
                  href={item.href}
                  className={`
                    text-sm
                    leading-6
                    text-gray-600
                    hover:text-gray-900
                  `}
                >
                  {item.name}
                </a>
              </div>
            ))}
          </nav>
        </div>
      </footer>
    );
  }
}
