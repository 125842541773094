import React from "react";
import { LogoProps } from "./Logo.types";

import style from "./Logo.module.scss";

const Logo: React.FC<LogoProps> = ({ size, animated }: LogoProps) => {
  const elementSize = () => {
    switch (size) {
      case "xs":
        return "w-4 h-4";
      case "sm":
        return "w-8 h-8";
      case "lg":
        return "w-32 h-32";
      case "xl":
        return "w-64 h-64";
      case "md":
      default:
        return "w-16 h-16";
    }
  };

  const elementStrokeWidth = () => {
    switch (size) {
      case "xs":
        return "20";
      case "sm":
        return "25";
      case "lg":
        return "19";
      case "xl":
        return "16";
      case "md":
      default:
        return "20";
    }
  };

  const elementAnimation = () => {
    if (animated) {
      return style.PaperClipAnimation;
    }
    return "";
  };

  // TODO: SVG ANIMATION
  return (
    <div
      className={`
        mx-auto
        text-indigo-600
        ${elementSize()}
      `}
    >
      <svg
        width={"100%"}
        height={"100%"}
        viewBox={"0 0 256 256"}
        version={"1.1"}
        xmlns={"http://www.w3.org/2000/svg"}
        xmlnsXlink={"http://www.w3.org/1999/xlink"}
      >
        <title>1024x1024@1x</title>
        <g
          stroke={"none"}
          strokeWidth={"1"}
          fill={"none"}
          fillRule={"evenodd"}
          strokeLinecap={"round"}
        >
          <path
            d={`M171.50917,22.5145507 C163.829389,18.5430335 155.257371,16.3613772
            145.793117,15.9695818 C131.596735,15.3818888 114.489866,20.1051554
            101.667958,32.3431129 C88.8460492,44.5810704 81.7171148,63.1712324
            80.5685183,72.3073191 C79.4199218,81.4434058 80.326988,191.580562
            80.5685183,200.170813 C80.8100487,208.761063 92.5617368,240.921175
            127.939684,240.921175 C163.317631,240.921175 176.988625,215.27019
            176.988625,200.170813 C176.988625,185.071436 176.988625,110.680083
            176.988625,102.987033 C176.988625,95.293982 169.086661,70.4217248
            143.898833,70.4217248 C118.711005,70.4217248 112.486817,98.0878603
            112.486817,102.987033 C112.486817,106.253147 112.486817,125.030271
            112.486817,159.318403`}
            id="Line"
            className={elementAnimation()}
            stroke="currentColor"
            strokeWidth={elementStrokeWidth()}
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default Logo;
