import { useGoogleLogin } from "@react-oauth/google";
import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

interface LoginFormGoogleButtonProps {
  onSuccess: (tokenResponse: any) => void;
  onFailure: (error: any) => void;
}

const LoginFormGoogleButton: React.FC<LoginFormGoogleButtonProps> = (
  props: LoginFormGoogleButtonProps
) => {
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => props.onSuccess(tokenResponse),
    onError: (error) => props.onFailure(error),
  });

  return (
    <button
      type={"button"}
      className={`
        grow
        inline-flex
        justify-center
        items-center
        rounded-md
      bg-indigo-600
        px-3
        py-2
        text-sm
        font-semibold
      text-white
        shadow-sm
      hover:bg-indigo-500
        focus-visible:outline
        focus-visible:outline-2
        focus-visible:outline-offset-2
      focus-visible:outline-indigo-500
    `}
      onClick={() => login()}
    >
      <div>
        <FontAwesomeIcon icon={faGoogle} size={"sm"} />
      </div>
      <div
        className={`
        ml-2
      `}
      >
        AUTHENTICATE
      </div>
    </button>
  );
};

export default LoginFormGoogleButton;
