import { Component } from "react";

import style from "./index.module.scss";

interface PrivatePolicyProps {}
interface PrivatePolicyState {}

export default class PrivatePolicy extends Component<
  PrivatePolicyProps,
  PrivatePolicyState
> {
  render() {
    return (
      <div className="bg-white px-6 py-32 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <p className="text-base font-semibold leading-7 text-indigo-600">
            KERNEL EVENTS
          </p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Privacy Policy
          </h1>
          <div className="mt-10 max-w-2xl">
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              1. Information Collection
            </h2>
            <p className="mt-6">
              Information is stored an in an encrypted format is used for
              identification within the tool.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              2. Information Use
            </h2>
            <p className="mt-6">
              Encrypted information is used solely for internal purposes related
              to user access and tool functionality. We do not share personal
              information with third parties.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              3. Data Security
            </h2>
            <p className="mt-6">
              We implement security measures to protect personal information
              from unauthorized access, disclosure, or alteration.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              4. Data Retention
            </h2>
            <p className="mt-6">
              Encrypted information is retained only for the duration of a
              agent's access to the tool.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              5. Changes to Privacy Policy
            </h2>
            <p className="mt-6">
              We reserve the right to update this privacy policy as needed.
              Agent's will be notified of any significant changes.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              6. Contact Information
            </h2>
            <p className="mt-6">
              For questions or concerns regarding this privacy policy, contact
              the administrators
            </p>
          </div>
        </div>
      </div>
    );
  }
}
