import { Component } from "react";

class Queries extends Component {
  render() {
    return (
      <div>
        <h1>Queries</h1>
      </div>
    );
  }
}

export default Queries;
