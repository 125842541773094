import axios from "axios";

import { getAPIEndpoint } from "./config";

const instance = axios.create({
  withCredentials: true,
});

class User {
  static getData = async () => {
    return await instance.get(getAPIEndpoint() + "/protected");
  };
}

export default User;
