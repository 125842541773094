import React, { useState } from "react";
import Logo from "../../Brand/Logo/Logo";
import { LoginFormProps } from "./LoginForm.types";
import style from "./LoginForm.module.scss";
import LoginFormGoogleButton from "./LoginFormGoogleButton";
import "animate.css";
import Auth from "../../../controllers/Auth";

interface LoginFormState {
  error?: string;
}

const LoginForm: React.FC<LoginFormProps> = () => {
  const [error, setError] = useState<string | undefined>();

  const onSuccess = async (token: any) => {
    await Auth.authGoogle(token.access_token);
    window.location.reload();
  };

  const onFailure = (error: any) => {
    setError(error);
  };

  return (
    <div
      className={`
        max-w-max
        bg-white
        shadow
        sm:rounded-lg
        flex
        flex-col
        justify-center
        items-center
        ${style.FadeInAndMov}
      `}
    >
      <div
        className={`
          px-4
          py-5
          sm:p-6
        `}
      >
        <div className={`pb-6`}>
          <Logo size={"sm"} animated={true} />
        </div>
        <h3
          className={`
            text-lg
            font-semibold
            leading-6
            text-gray-900
          `}
        >
          Kernel.Events
        </h3>
        <div
          className={`
            mt-2
            max-w-xl
            text-sm
            text-gray-500
          `}
        >
          <p>Connect to Application</p>
        </div>
        {error && (
          <div
            className={`
              mt-5
              flex
              text-red-700
              text-xs`}
          >
            {error}
          </div>
        )}
        <div className={`mt-5 flex`}>
          <LoginFormGoogleButton onSuccess={onSuccess} onFailure={onFailure} />
        </div>
      </div>
    </div>
  );
};

export default LoginForm;

// import { Component } from "react";
// import Logo from "../../Brand/Logo/Logo";

// import { LoginFormProps } from "./LoginForm.types";
// import style from "./LoginForm.module.scss";

// import LoginFormGoogleButton from "./LoginFormGoogleButton";

// import "animate.css";
// import Auth from "../../../controllers/Auth";

// interface LoginFormState {
//   error?: string;
// }

// export default class LoginForm extends Component<
//   LoginFormProps,
//   LoginFormState
// > {

//   onSuccess = async (token: any) => {
//     await Auth.authGoogle(token.access_token);
//     window.location.reload();
//   };

//   onFailure = (error: any) => {
//     this.setState({ error });
//   };

//   render() {
//     return (
//       <div
//         className={`
//           max-w-max
//         bg-white
//           shadow
//           sm:rounded-lg
//           flex
//           flex-col
//           justify-center
//           items-center
//           ${style.FadeInAndMov}
//         `}
//       >
//         <div
//           className={`
//             px-4
//             py-5
//             sm:p-6
//           `}
//         >
//           <div className={`pb-6`}>
//             <Logo size={"sm"} animated={true} />
//           </div>
//           <h3
//             className={`
//               text-lg
//               font-semibold
//               leading-6
//               text-gray-900
//             `}
//           >
//             Kernel.Events
//           </h3>
//           <div
//             className={`
//               mt-2
//               max-w-xl
//               text-sm
//               text-gray-500
//             `}
//           >
//             <p>Connect to Application</p>
//           </div>
//           {this.state?.error && (
//             <div
//               className={`
//               mt-5
//               flex
//               text-red-700
//               text-xs`}
//             >
//               {this.state?.error}
//             </div>
//           )}
//           <div className={`mt-5 flex`}>
//             <LoginFormGoogleButton
//               onSuccess={this.onSuccess.bind(this)}
//               onFailure={this.onFailure.bind(this)}
//             />
//           </div>
//         </div>
//       </div>
//     );
//   }
// }
