import react, { Component } from "react";
import Logo from "../../components/Brand/Logo/Logo";

import style from "./index.module.scss";
import LoginForm from "../../components/Elements/LoginForm/LoginForm";
import Footer from "../../components/Elements/Footer/Footer";

interface MainProps {}
interface MainState {
  value: number;
}

export default class Main extends Component<MainProps, MainState> {
  state: Readonly<MainState> = {
    value: 50,
  };

  sliderValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value)) {
      this.setState({ value });
    }
  };

  render() {
    return (
      <div>
        <main
          className={`h-screen w-screen p-5 flex flex-col box-border ${style.Background}`}
        >
          <div
            className={
              "grow flex flex-col justify-center items-center text-center"
            }
          >
            <LoginForm />
          </div>
        </main>
        <Footer />
      </div>
    );
  }
}
