import { Component } from "react";

class Sources extends Component {
  render() {
    return (
      <div>
        <h1>Sources</h1>
      </div>
    );
  }
}

export default Sources;
