import { googleLogout } from "@react-oauth/google";
import axios from "axios";

import { getAPIEndpoint } from "./config";

const instance = axios.create({
  withCredentials: true,
});

const invalidationMessage = {
  invalidate: true,
};

class Auth {
  static setToken(token: any) {
    localStorage.setItem("token", token);
  }

  static async isUserAuthenticated() {
    const token = Auth.getToken();
    if (token === null) return false;

    try {
      const response = await instance.get(getAPIEndpoint() + "/protected");
      return response.data;
    } catch (error) {
      Auth.logout();
      return false;
    }
  }

  static deauthenticateUser() {
    localStorage.removeItem("token");
  }

  static async logout() {
    await instance.get(getAPIEndpoint() + "/logout");
    Auth.invalidateGoogle();
    Auth.deauthenticateUser();
  }

  static getToken() {
    return localStorage.getItem("token");
  }

  static authGoogle = async (token: any) => {
    await instance.get(getAPIEndpoint() + `/google/token?access_token=${token}`);
    Auth.setToken(token);
  };

  static invalidateGoogle = async () => {
    googleLogout();
  };
}

export default Auth;
