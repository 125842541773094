import { Component } from "react";

import style from "./index.module.scss";

interface TermsAndConditionsProps {}
interface TermsAndConditionsState {}

export default class TermsAndConditions extends Component<
  TermsAndConditionsProps,
  TermsAndConditionsState
> {
  render() {
    return (
      <div className="bg-white px-6 py-32 lg:px-8">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <p className="text-base font-semibold leading-7 text-indigo-600">
            KERNEL EVENTS
          </p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Terms and Conditions
          </h1>
          <div className="mt-10 max-w-2xl">
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              1. Acceptance of Terms
            </h2>
            <p className="mt-6">
              By accessing and using this internal tool, you agree to comply
              with these terms and conditions. If you do not agree with these
              terms, please do not use the tool.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              2. Access and Use
            </h2>
            <p className="mt-6">
              Access to this tool is limited to authorized employees and
              personnel of the organization. You must not share your login
              credentials or access the tool on behalf of unauthorized
              individuals. Your use of the tool must comply with all applicable
              laws and regulations.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              3. Intellectual Property
            </h2>
            <p className="mt-6">
              All content, software, and materials provided in the tool are the
              property of of the organization and are protected by intellectual
              property laws. You may not reproduce, distribute, modify, or
              create derivative works from the content without prior written
              consent.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              4. Data Security
            </h2>
            <p className="mt-6">
              You are responsible for maintaining the security of your login
              credentials. You must report any unauthorized access or security
              breaches immediately to the relevant personnel.
            </p>
            <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
              5. Disclaimer
            </h2>
            <p className="mt-6">
              The tool is provided "as is," and The organization makes no
              warranties, express or implied, regarding its performance or
              availability. The organization is not responsible for any damages
              or losses resulting from the use of the tool.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

//

//
